import { graphql } from "gatsby";
import * as React from "react";
import Layout from "~/components/Layout";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { Section } from '~/components/elements/Section';
import { Icon, IconType } from "~/components/elements/Icon";

export default function legalPage({ data: { wpPage, wp }}) {
  return (
    <Layout wp={wp} wpPage={wpPage}>
      <Seo post={wpPage} />
      <Masthead title={wpPage?.title} />
      <Content content={wpPage?.content} />
    </Layout>
  );
}

export const Masthead = ({ title }) => {
  return (
    <div className="relative">
      <Section layoutName="LegalMasthead">
        <div className="container">
          <div className="space-y-4">
            <div className="flex items-center text-blue space-x-2.5">
              <Icon type={IconType.Flag} />
              <div>Legal</div>
            </div>
            <h1 className="text-h1-large font-display font-light">{title}</h1>
          </div>
        </div>
        <div className="container mx-auto absolute bottom-0 left-1/2 transform -translate-x-1/2">
          <div className="h-px bg-[#22382E] bg-opacity-20 w-full"></div>
        </div>
      </Section>
    </div>
  )
}

export const Content = ({ content }) => {
  return (
    <Section>
      <div className="container">
        <div className="max-w-[678px] mx-auto">
          <div className="prose" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </Section>
  )
}

export const pageQuery = graphql`
  query Legal($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
  }
`;
